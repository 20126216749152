// src/App.js
import React from 'react';
import './App.css';
import PortfolioCard from './components/PortfolioCard.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <PortfolioCard />
      </header>
    </div>
  );
}

export default App;
